<template>
  <div></div>
</template>

<script>
import {LOGOUT} from '@/core/services/store/auth.module';

export default {
  name: 'Logout',
  mounted() {
    this.$store.dispatch(LOGOUT);
  },
};
</script>
